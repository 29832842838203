import LandingPageLayout from "../components/landingPages/LandingPageLayout";
import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import outputPixelDensities from "../data/defaultPixelDensities";

const LandingNaturligVolum = () => {
    const heroImage = <StaticImage
        src={"../images/landingpage/naturlig_volum/hero_naturlig_volum_rev5.jpg"}
        alt={"Vippeextensions"}
        sizes={"(max-width: 900px) 50vw, 100vw"}
        width={1920}
        outputPixelDensities={outputPixelDensities}
    />
    const heroImageDesktop = <StaticImage
        src={"../images/landingpage/naturlig_volum/hero_desktop_rev1.jpg"}
        alt={"Vippeextensions"}
        sizes={"(max-width: 900px) 50vw, 100vw"}
        width={1920}
        outputPixelDensities={outputPixelDensities}
    />

    const gallery = [
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/1.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/2.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/3.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/4.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/5.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/6.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/7.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/8.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/klassisk/gallery/1.jpg"}
            alt={"Øye med klassiske vippeextensions"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/klassisk/gallery/2.jpg"}
            alt={"Øye med klassiske vippeextensions"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/klassisk/gallery/3.jpg"}
            alt={"Øye med klassiske vippeextensions"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/klassisk/gallery/4.jpg"}
            alt={"Øye med klassiske vippeextensions"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/klassisk/gallery/5.jpg"}
            alt={"Øye med klassiske vippeextensions"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/klassisk/gallery/6.jpg"}
            alt={"Øye med klassiske vippeextensions"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/klassisk/gallery/7.jpg"}
            alt={"Øye med klassiske vippeextensions"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />
    ];

    const perks = [
        {
            image: <StaticImage
                src={"../images/landingpage/perks/specialist_perk_rev2.jpg"}
                alt={"Grunnlegger Benedikte Villmark"}
                sizes={"(max-width: 450px) 50vw, 300px"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            caption: "Utføres av spesialister"
        },
        {
            image: <StaticImage
                src={"../images/landingpage/perks/fit_rev1.jpg"}
                alt={"Øye med volumvipper"}
                sizes={"(max-width: 450px) 50vw, 300px"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            caption: "Vi tilpasser vippene til dine øyne"
        },
        {
            image: <StaticImage
                src={"../images/landingpage/perks/location_rev2.jpg"}
                alt={"Salongen på Grünerløkka"}
                sizes={"(max-width: 450px) 50vw, 300px"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            caption: "I Oslo Sentrum og på Grünerløkka"
        }
    ];

    const services = [
        {name: "Nytt sett klassisk", price: "1590,-"},
        {name: "Nytt sett naturlig volum (2D-3D)", price: "1590,-"},
        {name: "Nytt sett dramatisk volum (4D+)", price: "1790,-"},
        {name: "Nytt sett brune vipper", price: "1790,-"},
        {name: "Påfyll 90 minutter", price: "990,-"},
        {name: "Påfyll 60 minutter", price: "790,-"}
    ];

    const videoSrc = '/background_video/naturlig_volum_rev8.mp4';

    return(
        <LandingPageLayout
            title={"Vippeextensions"}
            heroImage={heroImage}
            heroImageDesktop={heroImageDesktop}
            heroCaption={`
                Vippeextensions kan gi vippene fylde og lengde, og tilpasses dine ønsker.
            `}
            text={`
                 Vippeextensions er kunstige hår som blir limt på én og én naturlig vipp, 
                 for å skape en fyldig og en forlengende effekt. Vi har mange forskjellige design, 
                 tykkelser, lengder, farger og krøller for å skape ulike effekter. Vi tilpasser valget 
                 etter hvordan dine naturlige vipper ser ut, og hva som vil kle dine øyne og ditt ansikt.
                 Vippeextensions limes på dine naturlige vipper. Det betyr at de vokser ut og faller av med 
                 dine naturlige vipper. På den måten vil de ikke slite eller ødelegge vippene dine på noen måte. 
                 Å få vippeextensions er helt smertefritt både under behandlingen og senere.
            `}
            gallery={gallery}
            perks={perks}
            services={services}
            readMorePath={"/vippeextensions"}
            videoSrc={videoSrc}
        />
    )
};

export default LandingNaturligVolum;